import React from 'react';
import { AlertTriangle, Clock, RotateCw, ArrowRight } from 'lucide-react';

const MaintenancePage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 min-h-screen flex flex-col justify-center items-center py-12">
      <div className="text-center mb-12">
        <h1 className="text-3xl lg:text-4xl font-bold mb-4 text-white">
        Remove Background Tool Maintenance
        </h1>
        <div className="flex justify-center mb-6">
          <RotateCw size={48} className="text-[#F7AA01] animate-spin" />
        </div>
        <p className="text-lg text-gray-300 mb-4">
          We're currently improving our Remove Background service
        </p>
        <div className="bg-gray-700 p-6 rounded-lg max-w-2xl mx-auto">
          <div className="flex items-center gap-3 mb-4">
            <AlertTriangle size={24} className="text-[#F7AA01]" />
            <p className="text-white font-medium">
              This service is temporarily unavailable
            </p>
          </div>
          
          <div className="mb-6 border-l-2 border-[#F7AA01] pl-4">
            <p className="text-gray-300">
              Our team is working to bring you an enhanced Remove Background experience with improved 
              performance and new features. We apologize for any inconvenience this may cause.
            </p>
          </div>
          
          <div className="bg-gray-800 rounded-lg p-4 mb-6">
            <div className="flex items-center gap-2 mb-2">
              <Clock size={20} className="text-[#F7AA01]" />
              <h3 className="text-white font-medium">Expected Completion</h3>
            </div>
            <p className="text-gray-300">
              The service will be back online within 24 hours. Thank you for your patience.
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
  <a
    href="https://www.imgtoollab.com/"
    className="bg-gray-600 text-white px-6 py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-500 transition-colors duration-200"
  >
    Return to Home
  </a>
  <a
    href="https://www.imgtoollab.com/about"
    className="bg-[#F7AA01] text-[#2F2F30] px-6 py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-[#F7AA01]/80 transition-colors duration-200"
  >
    Check Status <ArrowRight size={18} />
  </a>
</div>;
        </div>
      </div>
      
      <div className="text-sm text-gray-400 text-center mt-auto">
        <p>If you need immediate assistance, please contact our support team.</p>
        <p className="mt-2">© {new Date().getFullYear()} Image Tools</p>
      </div>
    </div>
  );
};

export default MaintenancePage;
